import React from "react"
import { gql, useQuery } from "@apollo/client"
import BlogGridCard from "../BlogGridCard"

let hasResults

const SimilarArticles = ({ id, titleId }) => {
  const GET_SIMILAR_STRAPI_ARTICLES = gql`
    query allStrapiArticles($categoryId: Int!, $titleId: Int!) {
      articlesConnection(
        limit: 3
        sort: "created_at:desc"
        where: { id_ne: $titleId, category: { id: $categoryId } }
      ) {
        values {
          id
          Title
          Description
          thumbnail {
            url
          }
          author {
            firstName
            lastName
          }
          category {
            id
            name
          }
          UID
          razaroon {
            name
            title
            razaroonImage {
              url
            }
          }
        }
      }
    }
  `

  const { loading, error, data } = useQuery(GET_SIMILAR_STRAPI_ARTICLES, {
    variables: {
      categoryId: id,
      titleId: titleId,
    },
  })

  hasResults = data ? data.articlesConnection.values : []
  return (
    hasResults.length > 0 && (
      <div className="grid grid-1 grid-sm-2 grid-lg-3 grid-small-articles">
        {hasResults.map((node, i) => {
          return (
            <BlogGridCard
              imgUrl={
                node["razaroon"]
                  ? node["razaroon"]["razaroonImage"]["url"]
                  : node["thumbnail"]["url"]
              }
              subTitle={node["Description"]}
              title={node["Title"]}
              authorName={`${node["author"]["firstName"]} ${node["author"]["lastName"]}`}
              publishBio={node["author"]["publishBio"]}
              key={i}
              UID={node["UID"]}
            />
          )
        })}
      </div>
    )
  )
}

export default SimilarArticles
