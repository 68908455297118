import React from "react"
import Scrollspy from "react-scrollspy"
import Scroll from "./Scroll"
import slugify from "slugify"
import { Link } from "gatsby"
import parse from "html-react-parser"

const HTMLBlogNav = props => {
  const doc = parse(props.strapiArticles)

  let h2H3Array = []
  for (const node of doc) {
    if (node.type === "h2") {
      h2H3Array.push({ level: "h2", content: node.props.children[1] })
    }
    if (node.type === "h3") {
      h2H3Array.push({ level: "h3", content: node.props.children[1] })
    }
  }

  return (
    <div
      className={`blog-article-content-navigation ${props.sticky ? "alt" : ""}`}
      id={"blog-article-content-navigation"}
    >
      <Scrollspy
        items={h2H3Array.map(h2H3 =>
          slugify(h2H3.content, {
            replacement: "-",
            lower: true,
            strict: true,
          })
        )}
        currentClassName={"active"}
        offset={-20}
        className="blog-article-content-navigation-list"
      >
        {h2H3Array.map((h2H3, i) => {
          const slugifiedTitle = slugify(h2H3.content, {
            replacement: "-",
            lower: true,
            strict: true,
          })
          return (
            <li key={i}>
              <Scroll type="id" element={slugifiedTitle}>
                <Link
                  to={`#${slugifiedTitle}`}
                  className={`"article-nav-link" ${
                    h2H3.level === "h3" ? "subtitle" : ""
                  }`}
                >
                  <div className="content">
                    <div className="title">{h2H3.content}</div>
                  </div>
                </Link>
              </Scroll>
            </li>
          )
        })}
      </Scrollspy>
    </div>
  )
}

export default HTMLBlogNav
