import React, { useEffect } from "react"

const GoogleAdsBanner = () => {
  const container = {
    display: "block",
    marginBottom: "1rem",
  }

  const adStyle = {
    display: "block",
    textAlign: "center",
  }

  useEffect(() => {
    try {
      const adsbygoogle = window.adsbygoogle || []
      adsbygoogle.push({})
    } catch (e) {
      console.error(e)
    }
  }, [])
  return (
    <div style={container}>
      <ins
        className="adsbygoogle"
        style={adStyle}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-2820053008001775"
        data-ad-slot="5655133585"
      ></ins>
    </div>
  )
}

export default GoogleAdsBanner
