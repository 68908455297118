import React from "react"
import * as Markdown from "react-markdown"
import slugify from "slugify/slugify"

export function HeadingRenderer(props) {
  if (props.level === 3) {
    const slugifiedH3Title = slugify(props.children[0].props.value, {
      replacement: "-",
      lower: true,
      strict: true,
    })

    return <h3 id={slugifiedH3Title}>{props.children[0].props.value}</h3>
  }

  const Heading = Markdown.renderers.heading
  return <Heading {...props} />
}
