import React, { useState } from "react"
import Layout from "../components/layout"
import Menu from "../components/global/Menu"
import Footer from "../components/global/Footer"
import { graphql } from "gatsby"
import moment from "moment"
import Img from "gatsby-image"
import slugify from "slugify"
import ReactMarkdown from "react-markdown"
import SimilarArticles from "../components/global/SimilarArticles"
import CodeBlock from "../components/codeBlock"
import SEO from "../components/seo"
import readingTime from "reading-time"
import BlogNav from "../components/BlogNav"
import { Waypoint } from "react-waypoint"
import { HeadingRenderer } from "../components/renderers/HeadingRenderer"
import { Link } from "@reach/router"
import HTMLBlogNav from "../components/HtmlBlogNav"
import CallToAction from "../components/global/CallToAction"
import ReactHtmlParser from "react-html-parser"
import { Disqus, CommentCount } from "gatsby-plugin-disqus"
import GoogleAdsBanner from "../components/global/GoogleAdsBanner"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

let darkMode =
  typeof window !== `undefined` ? window.localStorage.getItem("darkMode") : null

const BlogPost = ({ data: { strapiArticles, site }, location }) => {
  const [stickyNav, setStickyNav] = useState(false)
  const razaroonImage = getImage(
    strapiArticles["razaroon"] && strapiArticles["razaroon"]["razaroonImage"]
      ? strapiArticles["razaroon"]["razaroonImage"]["localFile"]
      : strapiArticles["hero"]["localFile"]
  )

  let disqusConfig = {
    url: strapiArticles["Title"]
      ? strapiArticles["UID"]
        ? `${site.siteMetadata.siteUrl}/${strapiArticles["UID"]}/`
        : `${site.siteMetadata.siteUrl}/${slugify(
            strapiArticles["Title"].toLowerCase()
          )}/`
      : site.siteMetadata.siteUrl,
    identifier: strapiArticles["UID"]
      ? strapiArticles["UID"]
      : strapiArticles["Title"],
    title: strapiArticles["Title"],
  }

  return (
    <Layout
      bodyTheme={darkMode === "true" ? "theme-blog-dark" : "theme-blog-light"}
    >
      <SEO
        title={strapiArticles["Title"]}
        description={
          strapiArticles["Content"]
            ? strapiArticles["Content"]
                .replace(/<[^>]+>/g, "")
                .substring(0, 197) + "..."
            : strapiArticles["articleSections"]
                .map(section => {
                  return section.content
                })
                .toString()
                .substring(0, 197) + "..."
        }
        imageUrl={
          strapiArticles["razaroon"] &&
          strapiArticles["razaroon"]["razaroonImage"] &&
          strapiArticles["razaroon"]["razaroonImage"]["localFile"]
            ? strapiArticles["razaroon"]["razaroonImage"]["localFile"][
                "publicURL"
              ]
            : ""
        }
        readingTime={
          strapiArticles["Content"]
            ? readingTime(strapiArticles["Content"]).text
            : readingTime(
                strapiArticles["articleSections"]
                  .map(section => {
                    return section.content
                  })
                  .toString()
              ).text
        }
        publishedTime={
          strapiArticles["manual_created_at"]
            ? strapiArticles["manual_created_at"]
            : strapiArticles["created_at"]
        }
        UID={strapiArticles["UID"]}
      />
      <header>
        <Menu darkModeToggle="true" darkMode={darkMode === "true"} />
      </header>

      <main className="page-wrapper">
        <section className="section section-first">
          <div className="container">
            <div className="blog-article">
              <div className="blog-article-header">
                <h1>{strapiArticles["Title"] || "N/A"}</h1>
                <p> {strapiArticles["Description"] || "N/A"}</p>
                <div className="blog-article-header-meta">
                  {strapiArticles["author"]["avatar"] && (
                    <div className="blog-article-header-meta-author">
                      <img
                        alt=""
                        data-bg="true"
                        src={
                          strapiArticles["author"]["avatar"]["localFile"][
                            "publicURL"
                          ]
                        }
                      />
                    </div>
                  )}
                  <div className="blog-article-header-meta-info">
                    {strapiArticles["author"]["publishBio"] && (
                      <Link
                        to={slugify(
                          `/${strapiArticles["author"]["firstName"]} ${strapiArticles["author"]["lastName"]}`
                        ).toLowerCase()}
                        className="name"
                      >
                        {`${strapiArticles["author"]["firstName"]} ${strapiArticles["author"]["lastName"]}`}{" "}
                      </Link>
                    )}
                    {!strapiArticles["author"]["publishBio"] && (
                      <div>
                        {`${strapiArticles["author"]["firstName"]} ${strapiArticles["author"]["lastName"]}`}{" "}
                      </div>
                    )}
                    <div className="additional">
                      {strapiArticles["manual_created_at"]
                        ? moment(strapiArticles["manual_created_at"]).format(
                            "MMMM DD, Y"
                          )
                        : moment(strapiArticles["created_at"]).format(
                            "MMMM DD, Y"
                          )}
                      <div className="read-time">
                        {strapiArticles["Content"]
                          ? readingTime(strapiArticles["Content"]).text
                          : readingTime(
                              strapiArticles["articleSections"]
                                .map(section => {
                                  return section.content
                                })
                                .toString()
                            ).text}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-article-cover">
                <GatsbyImage image={razaroonImage} alt="razroo image" />
              </div>
              <div className="blog-article-content" id="blog-article-content">
                {strapiArticles["Content"] ? (
                  <>
                    <div className="blog-article-content-text">
                      {ReactHtmlParser(strapiArticles["Content"])}
                    </div>
                    <Waypoint
                      onEnter={() => setStickyNav(true)}
                      onLeave={() => setStickyNav(false)}
                    ></Waypoint>
                    <HTMLBlogNav
                      strapiArticles={strapiArticles["Content"]}
                      sticky={stickyNav}
                    />
                  </>
                ) : (
                  <>
                    <div className="blog-article-content-text">
                      {strapiArticles["articleSections"].map((section, idx) => {
                        const slugifiedTitle = slugify(section["title"], {
                          replacement: "-",
                          lower: true,
                          strict: true,
                        })
                        return (
                          <div key={idx}>
                            {idx !== 0 ? <hr /> : null}
                            <h2 id={slugifiedTitle}>{section.title}</h2>
                            <ReactMarkdown
                              source={section["content"]}
                              renderers={{
                                code: CodeBlock,
                                heading: HeadingRenderer,
                              }}
                            />
                          </div>
                        )
                      })}
                    </div>
                    <Waypoint
                      onEnter={() => setStickyNav(true)}
                      onLeave={() => setStickyNav(false)}
                    ></Waypoint>
                    <BlogNav
                      strapiArticles={strapiArticles}
                      sticky={stickyNav}
                    />
                  </>
                )}
              </div>
              <div className="blog-article-content">
                <div className="col-lg-12">
                  <CallToAction />
                  <GoogleAdsBanner />
                  <Disqus config={disqusConfig} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading text-center">
                  <div className="section-heading-text">
                    <h2>More articles similar to this</h2>
                  </div>
                </div>
                <SimilarArticles
                  id={strapiArticles["category"][0]["id"]}
                  titleId={strapiArticles["strapiId"]}
                />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    strapiArticles(id: { eq: $id }) {
      strapiId
      id
      Title
      Content
      articleSections {
        content
        title
        id
      }
      Description
      hero {
        localFile {
          childImageSharp {
            gatsbyImageData(
              height: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          publicURL
        }
      }
      created_at
      category {
        id
        name
      }
      manual_created_at
      author {
        firstName
        lastName
        avatar {
          localFile {
            publicURL
          }
        }
        publishBio
      }
      UID
      razaroon {
        name
        title
        razaroonImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            publicURL
          }
        }
      }
    }
  }
`

export default BlogPost
