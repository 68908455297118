export function headingParser(props) {
  const heading3Regex = /(###\s*)(.*)/g

  let heading3Match = props.match(heading3Regex)

  if (heading3Match) {
    return heading3Match.map(h3 => {
      let h3Object = { level: "h3", content: h3.replace(/###/g, "").trim() }
      if (h3Object.content.match(/(#\s*)(.*)/g)) {
        return undefined
      } else {
        return h3Object
      }
    })
  }
}
